.App {
    text-align: center;
    background-color: #f5f5f5;
    padding-bottom: 50px;
  }
  
  .App-header {
    background-color: #1e7a46;
    padding: 20px;
    color: white;
    margin-bottom: 40px;
  }
  
  .App-header h1 {
    margin: 0;
  }
  
  .App-header p {
    margin: 10px 0 0;
    font-size: 18px;
  }
  
  .solution {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    padding: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .solution h2 {
    /* color: #4ee712; */
    font-size: 18px;
    margin-bottom: 15px;
      color: #1e7a46;
    margin-bottom: 0px;
  }
  
  .details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .selected {
    background-color: #f8f9fa;
  }
  
  .selected .details {
    max-height: 500px; /* Adjust the maximum height as needed */
    padding-top: 20px;
  }
  
  .selected .details p,
  .selected .details ul {
    opacity: 1;
  }
  
  .selected .details ul {
    margin-top: 10px;
  }
  
  .selected .details ul li {
    list-style-type: disc !important;
  }
  
  .expanded {
    opacity: 1;
  }
  